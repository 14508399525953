import * as React from 'react';
import { Unstable_Grid2 as Grid, Typography, TextField, Select, MenuItem, FormControlLabel, Button, FormControl, FormHelperText, InputLabel, Checkbox, Snackbar, Alert, Link } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getNewPostFormModel, illnessTypes } from './UserInfoModel';
import * as FormHelper from '../../helpers/FormHelper';
import * as ValidationHelper from '../../helpers/ValidationHelper';
import { useTranslation, Trans } from 'react-i18next';
import { useScript } from '../../helpers/ScriptHelper';
import * as BackendHelper from '../../helpers/BackendHelper';
import LegalDialog from './LegalDialog/LegalDialog';
import ManualDialog from './ManualDialog/ManualDialog';
import useDateFormat from '../../custom-hooks/useDateFormat';
import dayjs from 'dayjs';
import * as ImageConversion from 'image-conversion';
import localeDe from 'dayjs/locale/de';

function Main(props) {
    const { setLoading } = props;

    const { t } = useTranslation();
    const dateFormat = useDateFormat();
    useScript('jquery-script', 'https://code.jquery.com/jquery-3.7.0.min.js', null);
    useScript('captcha-script', 'https://webappservices.festo.com/captcha2/botdetect/js/festo_captcha_client.js', null);
    useScript('captcha-custom-script', null,
        `
        var captchaInterval = null;
        var timeout = null;
        function insertCaptcha(){
            try {
                timeout = setTimeout(insertCaptcha, 2000);
                festo_captcha_client ({sCaptchaHost: "https://webappservices.festo.com",
                captchaDivSiblingID: "captchaBeforeMe",
                captchaStyle: "festoCaptchaRC2",
                formCaptchaTokenName: "captchaToken",
                formIDorName: "captcha-form"});
                clearTimeout(timeout);
            } catch {}
        }
        insertCaptcha();
     `);

    const [formModel, setFormModel] = React.useState(getNewPostFormModel());
    const [fileBinary, setFileBinary] = React.useState(null);
    const [isPreviewOpen, setIsPreviewOpen] = React.useState(false);
    const [isSuccessWindowOpen, setIsSuccessWindowOpen] = React.useState(false);
    const [snackbarModel] = React.useState({});
    const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false);
    const [legalDialogOpen, setLegalDialogOpen] = React.useState(false);
    const [manualDialogOpen, setManualDialogOpen] = React.useState(false);

    const fileInput = React.useRef();
    const formSubmitInput = React.useRef();

    const getFileBinaryString = async (file) => new Promise(resolve => {
        var reader = new FileReader();
        reader.onload = () => {
            let data = window.btoa(reader.result)
            resolve(data);
        };
        reader.readAsBinaryString(file);
    });

    const readFile = async e => {
        let file = e.target.files[0];
        if (file.type.startsWith("image/")) {
            var compressedFile = await ImageConversion.compressAccurately(file, 1500);
            let fileBinaryData = await getFileBinaryString(compressedFile);
            console.log(fileBinaryData);
            setFileBinary(fileBinaryData);
            formModel.notificationImageFile.value = fileBinaryData;
        }
        else if (file.type==="application/pdf") {
            var fileReader = new FileReader();
            var base64;
            // Onload of file read the file content
            fileReader.onload = function(fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result.split(',')[1];;
                // Print data in console
                console.log(base64);
                setFileBinary(base64);
                formModel.notificationImageFile.value = base64;
            };
            // Convert data to base64
            fileReader.readAsDataURL(file);
        }
        else {
            openSnackbar("error", "Bad file format");
        }
    }

    const previewForm = () => {
        if (ValidationHelper.validateForm(formModel, setFormModel))
            setIsPreviewOpen(true);
    }

    const postForm = async () => {
        try {
            setLoading(true);
            let captchaToken = await getCaptchaToken();
            var model = FormHelper.extractValues(formModel);
            model["captcha"] = captchaToken;
            let resp = await BackendHelper.postData(model);
            if (!resp.ok) {
                if (resp.status === 450) {
                    let responseText = await resp.text();
                    openSnackbar("error", t(responseText));
                }
                else
                    throw new Error('Something went wrong.');
            }
            else {
                openSnackbar("success", "Notification sent successfully");
                setIsPreviewOpen(false);
                setIsSuccessWindowOpen(true);
            }
        } catch (ex) {
            let errorMessage = "Something went wrong, please try again later";
            console.log(ex.message);
            if (ex.message === "CheckCaptcha")
                errorMessage = t(ex.message);
            openSnackbar("error", errorMessage);
        } finally {
            clearCaptchaTokenField();
            setLoading(false);
        }
    }

    const getCaptchaToken = async () => {
        formSubmitInput.current.click();
        for (let i = 0; i < 16; i++) {
            let captchaTokenElementValue = document.getElementById("captchaToken").value;
            console.log(captchaTokenElementValue);
            if (captchaTokenElementValue !== undefined && captchaTokenElementValue.length > 0)
                return captchaTokenElementValue;
            if (i === 15 || document.getElementById("festoCaptchaUserInput").value === "")
                throw new Error('CheckCaptcha');
            await new Promise(x => setTimeout(x, 200));
        }
        return "";
    }

    const clearCaptchaTokenField = () => {
        try {
            document.getElementById("captchaToken")[0].value = "";
            document.getElementById("festoCaptchaUserInput").value = "";
        } catch { }
    }

    const openSnackbar = (severity, text) => {
        snackbarModel["severity"] = severity;
        snackbarModel["text"] = text;
        setIsSnackbarOpen(true);
    }

    return (
        <Grid container spacing={7} className="main" p={3} sx={{ position: "relative", p: { xs: 0, sm: 3 } }}>
            <LegalDialog open={legalDialogOpen} setOpen={setLegalDialogOpen} />
            <ManualDialog open={manualDialogOpen} setOpen={setManualDialogOpen} />
            <Snackbar open={isSnackbarOpen} autoHideDuration={3000} onClose={() => setIsSnackbarOpen(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert severity={snackbarModel.severity} sx={{ width: '100%' }} variant="filled">
                    {snackbarModel.text}
                </Alert>
            </Snackbar>
            {isSuccessWindowOpen ?
                <React.Fragment>
                    <Grid item xs={12}>
                        <Typography variant="h4" align="center">
                            {t("SuccessPageMessage1")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4" align="center">
                            {t("SuccessPageMessage2")}
                        </Typography>
                    </Grid>
                </React.Fragment>
                :
                <React.Fragment>
                    <Link
                        component="button"
                        variant="body1"
                        underline="none"
                        onClick={() => setManualDialogOpen(true)}
                        style={{
                            position: "absolute",
                            top: "16px",
                            right: "20px",
                            fontSize: "28px"
                        }}
                    >
                        ?
                    </Link>
                    <Grid item xs={12}>
                        <Typography variant="h4">
                            {t("GetWellSoon")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ pb: 0 }}>
                        <Typography variant="h6">
                            {t("TextIntro")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label={t("Firstname")}
                            id="firstname"
                            variant="standard"
                            fullWidth
                            required
                            disabled={isPreviewOpen}
                            error={!formModel.firstname.isValid}
                            value={formModel.firstname.value}
                            onChange={e => FormHelper.updateProperty(e.target.value, "firstname", formModel, setFormModel)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label={t("Lastname")}
                            id="lastname"
                            variant="standard"
                            fullWidth
                            required
                            disabled={isPreviewOpen}
                            error={!formModel.lastname.isValid}
                            value={formModel.lastname.value}
                            onChange={e => FormHelper.updateProperty(e.target.value, "lastname", formModel, setFormModel)}
                        />
                    </Grid>
                    <Grid item xs={6} sx={{ pr: { xs: 0.5, sm: 3 } }}>
                        <TextField
                            label={t("FestoUserId")}
                            id="shortname"
                            variant="standard"
                            fullWidth
                            disabled={isPreviewOpen}
                            error={!formModel.shortname.isValid}
                            value={formModel.shortname.value}
                            onChange={e => FormHelper.updateProperty(e.target.value, "shortname", formModel, setFormModel)}
                        />
                    </Grid>
                    <Grid item xs={6} sx={{ pl: { xs: 0.5, sm: 3 } }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} locale={localeDe}>
                            <DesktopDatePicker
                                inputFormat={dateFormat}
                                value={formModel.birthdate.value}
                                onChange={e => FormHelper.updateProperty(e, "birthdate", formModel, setFormModel)}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label={t("Birthdate")}
                                    variant="standard"
                                    fullWidth
                                    required
                                    disabled={isPreviewOpen}
                                    error={!formModel.birthdate.isValid}
                                />
                                }
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sx={{ pb: 0 }}>
                        <Typography variant="h6">
                            {t("TextMail")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label={t("EmailInCaseOfQueries")}
                            id="email"
                            variant="standard"
                            fullWidth
                            disabled={isPreviewOpen}
                            error={!formModel.email.isValid}
                            value={formModel.email.value}
                            onChange={e => FormHelper.updateProperty(e.target.value, "email", formModel, setFormModel)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label={t("MobileInCaseOfQueries")}
                            id="phone"
                            variant="standard"
                            fullWidth
                            disabled={isPreviewOpen}
                            error={!formModel.phoneNumber.isValid}
                            value={formModel.phoneNumber.value}
                            onChange={e => FormHelper.updateProperty(e.target.value, "phoneNumber", formModel, setFormModel)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} display="flex" justifyContent="right">
                        <FormControl
                            variant="standard"
                            sx={{ minWidth: 120 }}
                            required
                            fullWidth
                            error={!formModel.illnessType.isValid}
                        >
                            <InputLabel id="illnessType-label">{t("Reason")}</InputLabel>
                            <Select
                                labelId="illnessType-label"
                                id="illnessType"
                                disabled={isPreviewOpen}
                                value={formModel.illnessType.value}
                                onChange={e => FormHelper.updateProperty(e.target.value, "illnessType", formModel, setFormModel)}
                            >
                                {illnessTypes.map((illnessType, index) => <MenuItem key={index} value={illnessType}>{t(illnessType)}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} display="flex" justifyContent="space-between" alignItems="end">
                        <LocalizationProvider dateAdapter={AdapterDayjs} locale={localeDe}>
                            <DatePicker
                                maxDate={formModel.sickDateTo.value}
                                minDate={dayjs().subtract(2, 'week')}
                                inputFormat={dateFormat}
                                value={formModel.sickDateFrom.value}
                                onChange={e => FormHelper.updateProperty(e, "sickDateFrom", formModel, setFormModel)}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label={t("IllnessDateFrom")}
                                    variant="standard"
                                    style={{ width: "47%" }}
                                    disabled={isPreviewOpen}
                                    required
                                    error={!formModel.sickDateFrom.isValid}
                                />
                                }
                            />
                        </LocalizationProvider>
                        <Typography style={{ display: 'inline-block' }}>
                            -
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs} locale={localeDe}>
                            <DatePicker
                                minDate={formModel.sickDateFrom.value}
                                maxDate={dayjs().add(26, 'week')}
                                inputFormat={dateFormat}
                                value={formModel.sickDateTo.value}
                                onChange={e => FormHelper.updateProperty(e, "sickDateTo", formModel, setFormModel)}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label={t("IllnessDateTo")}
                                    variant="standard"
                                    style={{ width: "47%" }}
                                    disabled={isPreviewOpen}
                                    required
                                    error={!formModel.sickDateTo.isValid}
                                />
                                }
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            <Trans
                                i18nKey="FileUploadText0"
                            />
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            { formModel.notificationImageFile.isValid &&
                                <Trans
                                    i18nKey="FileUploadText1"
                                    components={[<Link component="button" variant="body1" onClick={() => fileInput.current.click()} />]}
                                />     
                            }
                            { !formModel.notificationImageFile.isValid &&
                                <Trans
                                    i18nKey="FileUploadText1"
                                    components={[<Link component="button" color="error" variant="body1" onClick={() => fileInput.current.click()} />]}
                                />
                                    
                            }
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            <Trans
                                i18nKey="FileUploadText2"
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ p: 0, m: 0 }}>
                        <input
                            id={"image-upload-input"}
                            ref={fileInput}
                            type="file"
                            style={{ display: 'none' }}
                            accept="image/*, .pdf"
                            error={!formModel.notificationImageFile.isValid}
                            onChange={readFile}
                        />
                    </Grid>
                    {fileBinary !== null && fileInput.current.files[0].type === "application/pdf" &&
                        <Grid item xs={12} >
                            <embed src={"data:application/pdf;base64," + fileBinary } type="application/pdf" style={{ maxWidth: 300 }} />
                        </Grid>
                    }
                    {fileBinary !== null && fileInput.current.files[0].type.startsWith("image/") &&
                        <Grid item xs={12} >
                            <img src={'data:image/jpeg;base64,' + fileBinary} alt="preview" style={{ maxWidth: 300 }}></img>
                        </Grid>
                    }
                    <Grid item xs={12} display="flex" justifyContent="right" style={{ display: isPreviewOpen ? "flex" : "none" }}>
                        <Grid container p={0}>
                            <Grid item xs={12} md={"auto"}>
                                <div id="captchaBeforeMe"></div>
                            </Grid>
                            <Grid item xs={12} md={"auto"}>
                                <form id="captcha-form" onSubmit={e => e.preventDefault()}>
                                    <TextField
                                        id="festoCaptchaUserInput"
                                    />
                                    <input type="submit" id="submitBtn" ref={formSubmitInput} style={{ display: "none" }} />
                                </form>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            error={!formModel.confirmedLegal.isValid}
                        >
                            <FormControlLabel
                                label={<Typography variant="body1">
                                    {"* " + t("ConfirmReadLegal")} <Link component="button" variant="body1" onClick={() => setLegalDialogOpen(true)}>{t("LegalLink")}</Link>{t("LegalPart3")}
                                </Typography>
                                }
                                control={<Checkbox
                                    disabled={isPreviewOpen}
                                    checked={formModel.confirmedLegal.value}
                                    onChange={e => FormHelper.updateProperty(e.target.checked, "confirmedLegal", formModel, setFormModel)}
                                />}
                            />
                            {!formModel.confirmedLegal.isValid &&
                                <FormHelperText>{t("MustConfirm")}</FormHelperText>
                            }
                        </FormControl>
                    </Grid>
                    {!isPreviewOpen &&
                        <Grid item xs={12} display="flex" justifyContent="right">
                            <Button onClick={previewForm} variant="contained">
                                {t("Further")}
                            </Button>
                        </Grid>
                    }
                    {isPreviewOpen &&
                        <Grid item xs={12} display="flex" justifyContent="space-between">
                            <Button onClick={() => setIsPreviewOpen(false)} variant="contained" color="secondary">
                                {t("Back")}
                            </Button>
                            <Button onClick={postForm} variant="contained">
                                {t("SendNotification")}
                            </Button>
                        </Grid>
                    }
                </React.Fragment>
            }
        </Grid>
    );
}

export default Main;
